import { useEffect, useMemo, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import CustomTable from "../../../components/CustomTable";
import DateRangeInput from "../../../components/DatePicker";
import Input from "../../../components/Input";
import { PageStructureWrapper } from "../../../components/PageStructureWrapper";
import api from "../../../service/api";
import { dateToTimestamp } from "../../../utils/Date";
import { toastStyle } from "../../../utils/toastStyles";
import { ConsultaPropostasWrapper, ConsultaResultWrapper, SearchFields, SearchWrapper } from "../../ConsutaDePropostas/style";

export const RelatorioAntigoPage = () => {
  const [openModal, setOpenModal] = useState(false);

  const [proposalsReport, setProposalsReport] = useState([]);

  const columnsProposal = useMemo(() => {
    const columns = [
      {
        Header: 'CPF/CNPJ',
        accessor: 'cpf',
      },
      {
        Header: 'TIPO',
        accessor: 'type',
      },
      {
        Header: 'PROPOSTA',
        accessor: 'proposta',
      },
      {
        Header: 'TELEFONE',
        accessor: 'phone',
      },
      {
        Header: 'DATA HORA',
        accessor: 'dataHora',
      },
      {
        Header: 'IP',
        accessor: 'ip',
      },
      {
        Header: 'GEOLOCALIZAÇÃO',
        accessor: 'geolocalizacao',
        Cell: ({ value }: any) => {
          return value.replace(';', ' | ');
        },
      },
      {
        Header: 'RETORNO',
        accessor: 'ns',
      },
      {
        Header: 'TOKEN ENVIADO',
        accessor: 'tokenCliente',
      },
      {
        Header: 'HORÁRIO DO TOKEN ENVIADO',
        accessor: 'datetimeTokenCliente',
      },
      {
        Header: 'TOKEN INFORMADO',
        accessor: 'tokenInformado',
      },
      {
        Header: 'HORÁRIO INFORMADO NA AUTENTICAÇÃO',
        accessor: 'datetimeAutenticacao',
      },
      {
        Header: 'QTD. DE TENTATIVAS DA SELFIE',
        accessor: 'qtdTentativasSelfie',
      },
      {
        Header: 'TIPO LIVENESS',
        accessor: 'tipoLiveness',
      },
      {
        Header: 'RETORNO DO LIVENESS',
        accessor: 'liveness',
      },
      {
        Header: 'RETORNO DA VALIDAÇÃO',
        accessor: 'validacao',
      },
      {
        Header: 'QTD. DE ARQUIVOS SUBMETIDOS (DOC. IDENTIDADE)',
        accessor: 'qtdArquivosSubmetidosDocIdentidade',
      },
      {
        Header: 'QTD. DE ARQUIVOS SUBMETIDOS (OUTROS)',
        accessor: 'qtdArquivosSubmetidosOutros',
      },
      // Verifica se "upload" existe nos dados antes de adicionar a coluna
      ...(proposalsReport.some((row: any) => row.upload) ? [{
        Header: 'DOCUMENTOS SUBMETIDOS',
        accessor: 'upload',
        Cell: ({ value }: any) => {
          return value?.map((element: any) => {
            return element.description;
          }).join(' | ');
        },
      }] : []),
      {
        Header: 'HORÁRIO DA CONCLUSÃO TERMO',
        accessor: 'dateTimeConclusaoTermo',
      },
      {
        Header: 'HORÁRIO DA CONCLUSÃO CET',
        accessor: 'dateTimeConclusaoCet',
      },
      {
        Header: 'HORÁRIO DA CONCLUSÃO CCB',
        accessor: 'dateTimeConclusaoCcb',
      },
      {
        Header: 'HORÁRIO DA CONCLUSÃO SEGURO',
        accessor: 'dateTimeConclusaoSeguro',
      },

      {
        Header: 'HORÁRIO DA CONCLUSÃO SPF PJ',
        accessor: 'spf',
      },
      {
        Header: 'HORÁRIO DA CONCLUSÃO DA JORNADA',
        accessor: 'dateTimeConclusaoJornada',
      },
      {
        Header: 'BLOQUEIO',
        accessor: 'bloqueio',
      },
    ];

    // Verificar se os campos existem antes de adicionar as colunas
    if (proposalsReport.some((row: any) => row.bradesco_link)) {
      columns.push({
        Header: 'Bradesco Link',
        accessor: 'bradesco_link',
      });
    }

    if (proposalsReport.some((row: any) => row.ios_link)) {
      columns.push({
        Header: 'iOS link',
        accessor: 'ios_link',
      });
    }

    if (proposalsReport.some((row: any) => row.android_link)) {
      columns.push({
        Header: 'Android link',
        accessor: 'android_link',
      });
    }

    return columns;
  }, [proposalsReport]);

  const columnMinWidths = { firstName: '20px' };

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [time, setTime] = useState<any>(12);
  const [numeroProposta, setNumeroProposta] = useState<string>('');
  const [numeroContrato, setNumeroContrato] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    if ((startDate && endDate && proposalsReport) || (numeroProposta)) {
      setFilteredData(proposalsReport);
    }
  }, [startDate, endDate, proposalsReport, numeroProposta]);

  const applyFilters = () => {
    let filteredResult = proposalsReport;
    setFilteredData(filteredResult);
    if (numeroProposta && numeroProposta?.length === 8) {
      filteredResult = filteredResult.filter((item: any) => {
        console.log('item', item);
        return item.proposta.includes(numeroProposta)
      }
      );
    }

    // if (numeroContrato && numeroContrato.length === 10) {
    //   filteredResult = filteredResult.filter((item: any) =>
    //     item.contract.includes(numeroContrato)
    //   );
    // }

    // if (cpfCnpj && (cpfCnpj.length === 11 || cpfCnpj.length === 14)) {
    //   filteredResult = filteredResult.filter((item: any) =>
    //     item.document.includes(cpfCnpj)
    //   );
    // }

    setFilteredData(filteredResult);
  };

  const clearFilters = () => {
    setNumeroProposta('');
    setNumeroContrato('');
    setCpfCnpj('');
    setFilteredData(proposalsReport);
  }

  useEffect(() => {
    if (numeroContrato || numeroProposta || cpfCnpj) {
      applyFilters()
    } else {
      clearFilters()
    }

  }, [numeroContrato, numeroProposta, cpfCnpj])

  useEffect(() => {
    if (startDate && endDate && numeroProposta) {
      applyFilters()
    } else if ((startDate && endDate) || numeroProposta) {
      toast.loading("Buscando dados...", { style: toastStyle })
      api.get(`/dashboard/${startDate ? dateToTimestamp(startDate) : 'None'}/${endDate ? dateToTimestamp(endDate) : 'None'}/${numeroProposta ? numeroProposta : 'None'}/report`).then(response => {
        console.log(response.data)
        setProposalsReport(response.data.sheet)
        toast.dismiss()
      }).catch((err: any) => {
        toast.error("Erro ao buscar dados " + err)
      })
    }
  }, [startDate, endDate, numeroProposta])

  const generateCSV = () => {
    const formatNumber = (value: any, accessor: any) => {
      if (value === null || value === undefined) return '=""';
      if (accessor == 'geolocalizacao') {
        return value.replace(';', ' | ')
      }
      if (accessor === 'ns') {
        return value.toLocaleString('pt-BR');
      }
      if (accessor === 'upload' && Array.isArray(value)) {
        return value?.map((element: any) => element.description).join(' | ');
      }
      return `${value.toLocaleString('pt-BR')}`;
    };

    const isFaturamentoSerpro = (value: any) => {
      if (value)
        value = value.toString().toLowerCase();
      return !isNaN(value) || value.includes('dados') || value.includes('imagem') || value.includes('{');
    };

    const indexCpfCnpj = columnsProposal.findIndex(col => col.accessor === 'cpf') + 1;
    const csvColumns = [
      ...columnsProposal.slice(0, indexCpfCnpj),
      { Header: 'PESSOA', accessor: 'tipoPessoa' },
      ...columnsProposal.slice(indexCpfCnpj)
    ];

    // Incluindo novamente as colunas de faturamento
    const indexValidacao = csvColumns.findIndex(col => col.accessor === 'validacao') + 1;
    csvColumns.splice(indexValidacao, 0, { Header: 'FATURAMENTO', accessor: 'faturamentoValidacao' });
    csvColumns.push({ Header: 'FATURAMENTO', accessor: 'faturamentoJornada' });

    const csvContent = csvColumns.map(col => col.Header).join(';') + '\n' +
      proposalsReport.map((row: any) => {
        const tipoPessoa = row.cpf.length === 11 ? 'CPF' : 'CNPJ';
        const rowWithExtraColumns = {
          ...row,
          tipoPessoa: `${tipoPessoa}`,
          faturamentoValidacao: isFaturamentoSerpro(row.validacao) ? '1' : '0',
          faturamentoJornada: row.dateTimeConclusaoJornada !== "Pendente" ? '1' : '0'
        };

        return csvColumns.map(col => {
          const value = rowWithExtraColumns[col.accessor];
          const formattedValue = formatNumber(value, col.accessor);;
          return formattedValue;
        }).join(';');
      }).join('\n');

    const csvData = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(csvData);

    link.setAttribute('href', url);
    link.setAttribute('download', `${startDate?.toLocaleDateString()}_${endDate?.toLocaleDateString()}.csv`);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };




  return <PageStructureWrapper title="Detalhamento por Propostas">
    <ConsultaPropostasWrapper>
      <SearchWrapper>
        <SearchFields>
          <DateRangeInput titleHeader="Datas:"
            startDate={startDate} setStartDate={setStartDate}
            endDate={endDate} setEndDate={setEndDate}
          />
          <Input titleHeader="Número da Proposta:" placeholder="Ex: 123456" onChange={(e) => setNumeroProposta(e.target.value)} />
          {/* <Input titleHeader="Número do Contrato:" placeholder="Ex: 123456" onChange={(e) => setNumeroContrato(e.target.value)} disabled={!(startDate || endDate)} />
          <Input titleHeader="CPF/CNPJ:" placeholder="Ex: 1234567890" onChange={(e) => setCpfCnpj(e.target.value)} disabled={!(startDate || endDate)} /> */}
        </SearchFields>
        <Button onClick={generateCSV} leftIcon={<FiDownload />} label="Download" theme="primary" height="40px" />
      </SearchWrapper>
      <ConsultaResultWrapper onClick={() => setOpenModal(!openModal)}>
        {(startDate && endDate && proposalsReport || numeroProposta) &&
          <CustomTable maxHeight="calc(100vh - 300px)" minWidth="100%"
            columns={columnsProposal}
            data={filteredData}
            columnMinWidths={columnMinWidths} />}
      </ConsultaResultWrapper>
    </ConsultaPropostasWrapper>
  </PageStructureWrapper>
}