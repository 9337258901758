import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { Step, StepWrapper } from "./style";

interface StepJourneyProps {
    status: "error" | "P" | "D";
    stepName: string;
    size?: string;
}

export const StepJourney = ({ status, stepName, size }: StepJourneyProps) => {
    return <StepWrapper>
        <Step status={status} size={size}>
            {status === "D" &&
                <FaCheck fontSize={size ? Number(size.replaceAll('px', '')) / 2 : 40} />
            }
            {status === "error" &&
                <IoMdClose fontSize={size ? Number(size.replaceAll('px', '')) / 2 : 40} />
            }
        </Step>
        <span>{stepName}</span>
    </StepWrapper>
}

export default StepJourney;