import styled from "styled-components";

export const StepWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100px;
    text-align: center;

    span {
        font-size: 12px;
    }
`

const themeStep = {
    P: "#ccc",
    error: "#DB4141",
    D: "#5FC53B",
}

interface StepProps {
    status: keyof typeof themeStep,
    size?: string;
}

export const Step = styled.div<StepProps>`
    width: ${props => props.size || "60px"};
    height: ${props => props.size || "60px"};
    border-radius: 50%;
    background-color: ${props => themeStep[props.status]};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
`
